/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.title {
  @apply text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate;
}

.sidebar {

  .sidebar-item {
    @apply text-gray-300 hover:bg-gray-700 hover:text-white flex items-center px-2 py-2 text-sm font-medium rounded-md;

    & svg {
      @apply text-gray-300 mr-3 flex-shrink-0 h-6 w-6;
    }
    &.active{
      @apply bg-gray-900 text-white;
    }
  }
}

.base-text-input {
  @apply appearance-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm;
}

.base-button {
  @apply flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
}

nav.tabs {
  a {
    @apply appearance-none border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 py-2 px-1 text-center border-b-2 font-medium text-sm;
  }
  a.active {
    @apply border-blue-500 text-blue-600
  }
}